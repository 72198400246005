import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import EnumHelper from '../../shared/helpers/enum-helper';
import { ITSDeviceEndpoint } from '../../shared/models/dto/devices';
import { Endpoint } from '../../shared/models/dto/endpoint';
import { NameValue } from '../../shared/models/dto/general';
import { ReferenceDescription } from '../../shared/models/dto/opc';
import { ITSSensorEndpoint } from '../../shared/models/dto/sensor';
import { SelectListItem, TreeModel } from '../../shared/models/shared/common';
import { EndpointType } from '../../shared/models/shared/enums';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import AuthStore from '../../stores/auth-store';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class EditSensorDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly deviceId: number;
    @Prop({ default: null }) readonly deviceName: string;
    @Prop({ default: null }) readonly sensorId: number;
    @Prop({ default: false }) readonly show: boolean;

    endpoints: Endpoint[] = [];
    selectedItems: Endpoint[] = [];

    active: any[] = [];
    search: any = null;

    isExternalOpcServer = false;

    endpoint: Endpoint = {
        displayName: '',
        endpointServer: '',
        endpointType: '',
        companyId: null
    };
    endpointAddress = '';

    refs = this.$refs as any;
    loading = true;
    valid = true;
    isEdit = false;
    formTitle = '';
    nodes: TreeModel[] = [];
    endpointTypes: SelectListItem[] = [];

    errors: NameValue[] = [];

    sensorEndpoints: ITSSensorEndpoint[] = [];

    selectedEndpoints: Endpoint[] = []; 

    get headers() {
        return [
            { text: this.t('DisplayName'), value: 'displayName', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('EndpointType'), value: 'endpoint.endpointType', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('EndpointServer'), value: 'endpoint.endpointServer', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('Actions'), value: 'actions', sortable: false, width: '160px', }
        ];
    }

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            this.isExternalOpcServer = false;
            this.selectedEndpoints = [];
            this.sensorEndpoints = [];
            this.errors = [];

            this.loading = true;
            this.isEdit = !!this.deviceId;
            this.formTitle = `${this.t('Edit')} ${this.t('Sensor').toLowerCase()}`;
            this.endpointTypes = await EnumHelper.ConvertToSelectList(EndpointType);

            await this.getEndpoints();
            await this.getSensorEndpoints();

            this.loading = false;
        }
    }

    async getEndpoints() {
        await this.authService.get<Endpoint[]>(`/api/endpoints?$filter=companyId eq ${AuthStore.getUser().companyId}`).then((response) => {
            this.endpoints = response.content;
        });
    }

    executeSearch(value: string, search: string | null, item: Endpoint): boolean {
        return item.displayName.toLowerCase().includes(search.toLowerCase()) ||
            item.endpointType.toLowerCase().includes(search.toLowerCase()) ||
            item.endpointServer.toLowerCase().includes(search.toLowerCase());
    }

    async getSensorEndpoints() {
        await this.authService.get<ITSSensorEndpoint[]>(`/api/itssensorEndpoints/${this.deviceId}/${this.sensorId}`).then(async(response) => {
            this.sensorEndpoints = response.content["data"];
            if (this.sensorEndpoints) {
                this.selectedEndpoints = this.sensorEndpoints.map(x => x.endpoint);
            }
        });
    }

    async expandNodes(item) {
        return this.authService.getList<ReferenceDescription>(`/api/opc/browse?opcEndpoint=${this.endpoint.endpointServer}&nodeId=${item.key}`).then(async(response) => {
            const children = response.map(n => <TreeModel>({ key: n.nodeId, name: n.displayName, type: n.nodeClass, children: n.nodeClass === 'Variable' ? undefined : [] }));
            if (children.length > 0) {
                item.children.push(...children);
            }
        });
    }

    onItemClick(item) {
        const deviceEndpoint: ITSDeviceEndpoint = {
            endpointAddress: item.key,
            endpointId: this.endpoint.id,
            iTSDeviceId: this.deviceId,
            displayName: `${this.deviceId}-${this.deviceName}`
        };

        this.authService.postOrPut('/api/deviceEndpoints', deviceEndpoint, this.deviceId).then((response) => {
            if (!response.isError) {
                this.save(this.endpoint);
            }
        });       
    }

    async onEndpointSelect(item: Endpoint) {
        if (item.endpointType === EndpointType.OPCUAEXTERNAL) {
            this.isExternalOpcServer = true;
            this.endpoint = item;
            await this.authService.getList<ReferenceDescription>(`/api/opc/browse?opcEndpoint=${this.endpoint.endpointServer}`).then(async(response) => {
                this.nodes = response.map(n => <TreeModel>({ key: n.nodeId, name: n.displayName, type: n.nodeClass, children: [] }));
            });            
        }
        else {
            this.isExternalOpcServer = false;
            this.save(item);
        }
    }

    save(item: Endpoint) {
        const itsSensorEndpoint: ITSSensorEndpoint = {
            iTSDeviceId: this.deviceId,
            endpointId: item.id,
            displayName: item.displayName,
            sensorId: this.sensorId
        };

        this.authService.post<void>(
            `/api/ITSSensorEndpoints/`,
            itsSensorEndpoint
        ).then(async(response) => {
            if (!response.isError) {               
                this.swalToast(2000, 'success', this.t('Successful'));
                this.isExternalOpcServer = false;
                await this.getSensorEndpoints();
            }
        });
    }

    async deleteItem(deviceId: number, sensorId: number, endpointId: number) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/itsSensorEndpoints/${deviceId}/${sensorId}/${endpointId}`).then(async(response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        await this.getSensorEndpoints();
                    }
                    else {
                        this.swalToast(2000, 'error', this.t('SomethingWrong'));
                    }
                });
            }
        });
    }

    close() {
        this.$emit('completed', false);
    }
}