import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Sensor } from '../../shared/models/dto/sensor';
import { NameValue } from '../../shared/models/dto/general';
import { CreateLoRaNode, LoraDevice, CreateBleDevice, BleDevice } from '../../shared/models/dto/devices';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default,
        deviceForm: require('@/views/_components/device/device-form.vue').default
    }
})
export default class EditCreateDeviceDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: string;
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: '' }) readonly protocolName: string;

    refs = this.$refs as any;
    valid = true;
    isEdit = false;
    isRead = false;
    loading = false;
    formTitle = '';
    errors: NameValue[] = [];
    sensors: Sensor[] = [];
    seriesList: any[] = [];

    initialValues: LoraDevice | BleDevice = {
        id: null,
        name: null,
        description: null,
        vendorDeviceId: null,
        vendorName: null,
        appKey: null,
        communicationProtocolVersionId: null,
        companyId: null,
        deviceEUI: null,
        tagId: null,
        uplinkIntervalInSeconds: null,
        communicationProtocolVersion: null,
        communicationProtocolVersionDisplayName: null,
        sensors: null,
        protocolDisplayName: null,
        protocolName: null,
        vendorDeviceName: null,
        vendorId: null,
        isDeleted: false,
    };

    device = this.initialValues;

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {          
            this.errors = [];
            this.device = this.initialValues;
            this.isEdit = !!this.id;

            this.formTitle = `${this.isEdit ? this.t('Edit') : this.t('New')} ${this.t('Device').toLowerCase()}`;

            this.loading = true;

            if (this.isEdit) {
                this.getDevice().then((response) => {
                    this.device = response;
                    setTimeout(() => this.loading = false, 0);
                });
            }
            else {
                this.loading = false;
            }
        }
    }

    getDevice(): Promise<LoraDevice | BleDevice> {
        return new Promise((resolve) => {
            this.authService.get<LoraDevice | BleDevice>(
                `/api/${this.protocolName}devices/${this.id}`).then((response) => {
                resolve(response.content);
                return response.content;
            });
        });
    }

    save() {
        if (this.refs.formComponent.$refs.form.validate()) {
            this.errors = [];
            let device: CreateLoRaNode | CreateBleDevice = {
                companyId: 1,
                name: this.device.name,
                description: this.device.description,
                communicationProtocolVersionId: this.device.communicationProtocolVersionId,
                uplinkIntervalInSeconds: this.device.uplinkIntervalInSeconds,
                vendorDeviceId: this.device.vendorDeviceId,
                appKey: null,
                deviceEUI: null,
                tagId: null,
                
            };

            switch (this.device.protocolName) {
                case "LoRa":
                    device = {
                        ...device,
                        appKey: (this.device as LoraDevice).appKey.replace(/[^A-Za-z0-9]/g, ''),
                        deviceEUI: (this.device as LoraDevice).deviceEUI.replace(/[^A-Za-z0-9]/g, ''),
                    } as CreateLoRaNode;
                    break;
                case "Ble":
                    device = {
                        ...device,
                        tagId: (this.device as BleDevice).tagId.replace(/[^A-Za-z0-9]/g, ''),
                    } as CreateBleDevice;
            }

            const key: string = this.device.id ? this.device.id.toString() : '';

            this.authService.postOrPut<void>(
                `/api/${this.device.protocolName}devices/${key}`,
                device,
                key
            ).then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('Successful'));
                    this.close(true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });
        }
    }

    close(mustReload: boolean) {
        this.$emit('completed', mustReload);
    }
}
